import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { type ATeamWorkplaces } from "../types";
import { FacilityCard } from "./card/FacilityCard";
import { FacilityCardGhost } from "./card/FacilityCard.ghost";

interface ATeamListProps {
  workplaces: ATeamWorkplaces;
  isLoading: boolean;
}

function BottomText(props: ATeamListProps) {
  const { workplaces: workpalces, isLoading } = props;

  if (isLoading) {
    return null;
  }

  const isATeamWorkpalceExists = Object.keys(workpalces).length > 0;

  if (isATeamWorkpalceExists) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Text variant="h4" sx={{ fontSize: "18px", fontWeight: "600", color: "#424242" }}>
          Not seeing a facility you really like?
        </Text>
        <Text variant="body2" sx={{ fontSize: "12px" }}>
          Tap <ExternalLink to="">here</ExternalLink> for tips on how to be added to a facility’s
          A-Team.
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Text variant="body1">
        No facilities have added you for their A-Team. Tap <ExternalLink to="">here</ExternalLink>{" "}
        for tips on how to be added to a facility’s A-Team.
      </Text>
    </Stack>
  );
}

export function ATeamList(props: ATeamListProps) {
  const { workplaces: workpalces, isLoading } = props;

  return (
    <Stack gap={1}>
      <Text
        variant="h2"
        sx={{
          color: "#424242",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        A-Team Facilities
      </Text>
      {Object.values(workpalces).map((workplace) => (
        <FacilityCard key={workplace.id} facilityId={workplace.id} facilityName={workplace.name} />
      ))}
      <BottomText workplaces={workpalces} isLoading={isLoading} />
      {isLoading &&
        Array.from({ length: 5 })
          .fill(null)
          // eslint-disable-next-line react/no-array-index-key
          .map((_, index) => <FacilityCardGhost key={index} />)}
    </Stack>
  );
}
