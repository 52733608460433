import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Chip, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { PageWithHeader } from "@src/appV2/lib/Layouts";

import { useGetATeamWorkplaces } from "../useGetATeamWorkplaces";
import { ATeamList } from "./ATeamList";

export function ATeamPage() {
  const { aTeamWorkplaces, isATeamWorkplaceLoading, refetchATeamWorkplaces } =
    useGetATeamWorkplaces();
  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="A-Team"
            leftCta={
              <BackButtonLink
                alwaysUseDefaultBackTo
                defaultBackTo={DeprecatedGlobalAppV1Paths.RATINGS}
              />
            }
          />
        }
        onRefresh={refetchATeamWorkplaces}
      >
        <Stack gap={2}>
          <Stack>
            <Text
              variant="h2"
              sx={{
                color: "#424242",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              Your A-Teams
            </Text>
            <ExternalLink to="" underline="none">
              <Chip
                label="Learn more"
                variant="outlined"
                size="small"
                sx={{ borderColor: "#0000" }}
              />
            </ExternalLink>
          </Stack>
          <ATeamList workplaces={aTeamWorkplaces} isLoading={isATeamWorkplaceLoading ?? false} />
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
