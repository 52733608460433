import { InternalLink, Text } from "@clipboard-health/ui-react";
import { Button, Card, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { generatePath } from "react-router-dom";

export interface FacilityCardProps {
  facilityId: string;
  facilityName: string;
}

export function FacilityCard(props: FacilityCardProps) {
  const { facilityId, facilityName } = props;

  return (
    <Card
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "8px",
      }}
    >
      <CardContent sx={{ "&:last-child": { padding: 0 }, padding: 0 }}>
        <Stack justifyContent="space-between" alignItems="center" gap={3}>
          <Text
            variant="h3"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              alignSelf: "flex-start",
              width: "100%",
              color: "#424242",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {facilityName}
          </Text>
          <InternalLink
            underline="none"
            variant="body2"
            to={generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
              facilityId,
            })}
            sx={{
              alignSelf: "flex-end",
            }}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.OPENED_A_TEAM_FACILITY_PROFILE, {
                facilityId,
              });
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{
                borderRadius: "64px",
                height: "32px",
                color: "#6A67CE",
                borderColor: "#6A67CE",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              View Profile
            </Button>
          </InternalLink>
        </Stack>
      </CardContent>
    </Card>
  );
}
