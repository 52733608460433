import { isDefined } from "@clipboard-health/util-ts";

import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "../FeatureFlags";
import { type GetWorkerRankingsQuery, useGetWorkerRankings } from "./api/useGetWorkerRankings";
import { ATeamHcpStatus, type ATeamWorkplaces } from "./types";

export function useGetATeamWorkplaces() {
  const { status: aTeamStatus } = useCbhFlag(CbhFeatureFlag.A_TEAM_HCP, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.A_TEAM_HCP],
  });
  const isWorkerAteamEnabled = [ATeamHcpStatus.A_TEAM, ATeamHcpStatus.PRIORITY_ACCESS].includes(
    aTeamStatus
  );

  const query: GetWorkerRankingsQuery = {
    include: ["workplace"],
    fields: {
      ranking: ["isOnATeam", "workerType", "workplace.id"],
      workplace: ["name"],
    },
    filter: {
      isOnATeam: true,
    },
  };

  const {
    data: workerRankings,
    isLoading,
    refetch: refetchATeamWorkplaces,
  } = useGetWorkerRankings(query, {
    enabled: isWorkerAteamEnabled,
  });

  if (!isWorkerAteamEnabled) {
    return {
      aTeamWorkplaces: {},
      isWorkerAteamEnabled: false,
      aTeamStatus,
      aTeamWorkplacesCount: 0,
    };
  }

  const aTeamWorkplaces = (workerRankings?.data ?? []).reduce<ATeamWorkplaces>(
    (accumulator, ranking) => {
      const workplace = workerRankings?.included?.find(
        (included) =>
          included.type === "workplace" && included.id === ranking.relationships?.workplace?.data.id
      );
      const workplaceId = workplace?.id;
      if (isDefined(workplaceId) && isDefined(ranking.attributes.workerType)) {
        if (accumulator[workplaceId]) {
          accumulator[workplaceId].workerTypes.push(ranking.attributes.workerType);
        } else {
          accumulator[workplaceId] = {
            name: workplace?.attributes.name ?? "",
            id: workplaceId,
            workerTypes: [ranking.attributes.workerType],
          };
        }
      }

      return accumulator;
    },
    {}
  );

  return {
    aTeamWorkplaces,
    isWorkerAteamEnabled,
    aTeamStatus,
    aTeamWorkplacesCount: Object.keys(aTeamWorkplaces).length,
    isATeamWorkplaceLoading: isLoading,
    refetchATeamWorkplaces,
  };
}
